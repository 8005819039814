/* BOOTSTRAP IMPORT JS */

/* Document
   ========================================================================== */

/**
 * 1. Dans la liste des composants qui sont ajoutés par bootstrap vous pouvez commenter/décommenter ceux que vous avez besoin/pas besoin.
 * 2. Si jamais vous venez de mettre à jour Bootstrap vers une nouvelle version majeure pensez à check le dossier "./node_modules/bootstrap/js/dist/" s'il n'y a pas de nouveaux fichiers js qui ont été ajoutés dans cette nouvelle version et qui ne sont pas proposés encore dans la liste ci-dessous.
 */

/* Bootstrap Import
   ========================================================================== */
// Configuration
// import alert from "bootstrap/js/src/alert";
// import base-component from "bootstrap/js/src/base-component";
// import button from "bootstrap/js/src/button";
// import carousel from "bootstrap/js/src/carousel";
// import collapse from "bootstrap/js/src/collapse";

// Nécessaire pour utiliser plusieur JS de Bootstrap :
// (pensez à installer le package avant de décommenter la ligne suivante en tappant dans la console "npm install @popperjs/core")
// import popper from '@popperjs/core';

// import dropdown from "bootstrap/js/src/dropdown";
// import modal from "bootstrap/js/src/modal";
// import offcanvas from "bootstrap/js/src/offcanvas";
// import popover from "bootstrap/js/src/popover";
// import scrollspy from "bootstrap/js/src/scrollspy";
// import tab from "bootstrap/js/src/tab";
// import toast from "bootstrap/js/src/toast";
// import tooltip from "bootstrap/js/src/tooltip";